<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SAVINGS HISTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              OR Date
            </th>
            <th class="text-center text-uppercase">
              OR No
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
            <th class="text-center text-uppercase">
              Expiration Date
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td class="text-center">
              {{ item.date_transaction }}
            </td>
            <td class="text-center">
              {{ item.or_no }}
            </td>
            <td class="text-center">
              {{ formatPrice(parseFloat(item.deposit)+parseFloat(item.withdrawal) )}}
            </td>
            <td class="text-center">
              {{ formatPrice(item.balance) }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>

  export default {
    props: {
      data: Array,
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
    },
  }
</script>
